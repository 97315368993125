<template>
  <div>
    <House>INTERIM FUNDING INSPECTIONS</House>
    <v-container fluid>
      <v-row>
        <v-col class="align-self-center text-center"><h2>What we do</h2>
          <ul style="list-style-position: inside;">
            <li>Interim Funding (Construction) Inspections</li>
            <li>Residential Draw Inspections</li>
            <li>Commercial Draw Inspections</li>
            <li>Traditional Reports</li>
            <li>Mobile reporting (BankLabs)</li>
          </ul>
          <img style="margin-top: 20px;" src="/inspector.jpg"><br>
        </v-col>
        <v-col class="align-self-center">
          <p class="text-center">Allgood Inspections is a company filled with seasoned
             experts in the construction industry. We take pride in
             exceptional reporting and quality control to ensure
             lender and buyer satisfaction.
          </p>
          <p class="text-center">
            We provide traditional reports that allow our lenders to
            see proper evaluations for each builder draw. This is a
            unique quality to traditional reports that other
            inspection companies just don’t provide.
          </p>
          <p class="text-center" style="font-weight: bold;">What does this mean?</p>
          <p class="text-center">
            You are getting an on-the-job evaluation that provides
            uniqueness to allocated building cost percentages to
            construction completion vs. percent drawn. This
            heightens accuracy for loan-to-value ratio improving
            loan integrity and keeping contractors honest.
          </p>
          <p class="text-center">
            Working with Allgood Inspections allows our clients to
            remain diligent with construction loans while remaining
            completely unbiased with buyers and builders in which
            we provide as a 3rd party service.
          </p>
          <p class="text-center">
            <v-btn x-large dark color="#0d3874" href="/report.pdf" target="_blank">Sample Report</v-btn>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center" style="font-size: 24px; font-weight: 400;">Call <a href="3017691181">301.769.1181</a> or email <a href="info@allgoodinspections.com">info@allgoodinspections.com</a> to inquire our rates</v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import House from "../components/House";
export default {
  name: "Construction",
  components: {House}
}
</script>

<style scoped>

</style>
