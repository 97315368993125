<template>
  <div>
    <House>PROUDLY SERVING THE GREATER SAN ANTONIO AND HILL COUNTRY AREAS</House>
    <div class="video-container">
      <video autoplay class="video-bg">
        <source src="/c54bb1eeea.mp4" type="video/mp4">
      </video>
    </div>
    <v-row style="background-color: #0d3874">
      <v-col class="text-center">
        <v-btn
            class="bottom-button"
            to="/construction"
            style="background-size: cover; background-image: url('/framing.jpg'); height: 204px; width: 230px; font-weight: bold;"
            dark>
          Construction Inspections
        </v-btn>
      </v-col>
      <v-col class="text-center">
        <v-btn
            class="bottom-button"
            to="/residential"
            style="background-size: cover; background-image: url('/screen_2x.jpg'); height: 204px; width: 230px; font-weight: bold;"
            dark>
          Residential Inspections
        </v-btn>
      </v-col>
      <v-col class="text-center">
        <v-btn
            class="bottom-button"
            style="background-size: cover; background-image: url('/screen_3x.jpg'); height: 204px; width: 230px; font-weight: bold;"
            dark>
          Residential Appraisals<br>
          (coming soon)
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import House from "../components/House";
  export default {
    name: 'Home',

    components: {
      House
    },
  }
</script>

<style>
.video-bg {
  position: relative;
  width: auto;
  min-width: 100%;
  height: auto;
  background-size: cover;
  bottom: 300px;
}
video {
  display: block;
}
.video-container {
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 548px);
}

.bottom-button > span{
  position: absolute;
  bottom: 2px;
}
</style>
