<template>
  <div style="background-image: url('/house.jpeg'); background-size: cover; height: 118px;">
    <div style="position: absolute; background-color: #0d3874AA; height: 118px; width: 100%; color: white;" class="d-flex justify-center">
        <svg stroke="#ffffff" fill="#ffffff" width="150" height="118"><line x1="15" x2="134.34658986103" y1="59" y2="59" stroke-linecap="round" fill="none"></line></svg>
        <div class="align-self-center" style="font-weight: 700; font-size: 21px"><slot></slot></div>
        <svg stroke="#ffffff" fill="#ffffff" width="150" height="118"><line x1="15" x2="134.34658986103" y1="59" y2="59" stroke-linecap="round" fill="none"></line></svg>
    </div>
  </div>
</template>

<script>


export default {
  name: "House"
}
</script>

<style scoped>

</style>
