<template>
  <div>
    <House>YOUR INSPECTION</House>
    <v-container fluid>
      <v-row>
        <v-col>
          <h1 style="padding-bottom: 10px;">Residential Home Inspections</h1>
          <p>Whether you’re buying, selling, or maintaining your home, Allgood Inspections will be there to provide you a
            professional evaluation of the property’s current condition. We perform full-service home inspections to bring
            attention to possible expenses. We guarantee that this inspection will be thorough, objective, and completely
            unbiased. We strictly follow the Standards of Practice for home inspections, established by TREC. Trust JW
            Resources to remove the uncertainty in home transactions by providing you with an unbiased report of the
            home’s condition.</p>
          <p>Reports will always be delivered promptly to save you time and money! Clients are not required to attend the
            home inspection, but this is strongly advised as an on-site review with the inspector will allow you to ask
            questions as defects are identified and make note of major problems. All reports will be delivered with
            Spectora, a web-based report software that provides both HTML and PDF output; this means we can add videos to
            your report.</p>
          <p>Curious to know what a standard home inspection report includes? Check out our sample report for a quick
            glance at what our reports will provide you!</p>
        </v-col>
        <v-col>
          <img src="/sample.png" style="width: 50%;">
          <v-btn color="#0d3874" dark href="https://www.spectora.com/home-inspectors/texas-professional-inspections-llc/sample_report?sample_id=232" target="_blank">Sample Report</v-btn>
          <h2>Our Services</h2>
          <ul>
            <li>Home Inspection (General)</li>
            <li>Builders Warranty Inspection</li>
            <li>Pre-listing Inspection</li>
            <li>4-Point Inspection
              <ul>
                <li>Roof, Electrical, HVAC, Plumbing</li>
              </ul>
            </li>
          </ul>
        </v-col>
      </v-row>
      <v-row><h1 style="padding-left: 10px;">Areas Inspected During a Home Inspection</h1></v-row>
      <v-row>
        <v-col>
          <v-row>
            <v-col class="text-right"><img style="max-height: 32px;" src="/roof.png"></v-col>
            <v-col><h2>Roof</h2>
              We provide detailed examination of roof structures, coverings (shingles, tiles, clay), flashing, and penetrations.
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right"><img style="max-height: 32px;" src="/attic.png"></v-col>
            <v-col><h2>Attics</h2>
              We inspect attics to ensure proper ventilation, check for moisture, assess the level of insulation, and more.
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right"><img style="max-height: 64px;" src="/hvac.png"></v-col>
            <v-col><h2>HVAC</h2>
              We inspect operating ranges for heating and cooling systems as well as examine conditions of ducting, filters, and major equipment.
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right"><img style="max-height: 64px;" src="/foundation.png"></v-col>
            <v-col><h2>Foundations</h2>
              We inspect conditions of foundations, basements and crawlspaces for signs of shifting/cracked foundations and previous water damage.
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right"><img style="max-height: 64px;" width="64" src="/screen.svg"></v-col>
            <v-col><h2>Electrical</h2>
              We inspect interior and exterior systems including service drops, service masts, grounding, meters, outlets, circuit breakers, and other such systems.
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right"><img style="max-height: 64px;" src="/plumbing.png"></v-col>
            <v-col><h2>Plumbing</h2>
              We inspect plumbing fixture functions, including tubs, sinks, hoses, showers, water shut-off valves, and irrigation systems to identify leaks and deficiencies.
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right"><img style="max-height: 64px;" src="/appliances.png"></v-col>
            <v-col><h2>Appliances</h2>
              We inspect all major appliances in their normal operating range.
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right"><img style="max-height: 64px;" src="/doors.png"></v-col>
            <v-col><h2>Windows &amp; Doors</h2>
              We inspect doors and windows for signs of water damage, proper sealing, and overall performance.
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <img src="/house.jpg">
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import House from "../components/House";
export default {
  name: "Residential",
  components: {House}
}
</script>

<style scoped>

</style>
