<template>
  <v-app>
    <v-app-bar
      app
    >
      <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="hidden-lg-and-up"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item to="/">
                <v-list-item-title>Home</v-list-item-title>
              </v-list-item>
              <v-list-item to="/construction">
                <v-list-item-title>Construction Inspections</v-list-item-title>
              </v-list-item>
              <v-list-item to="/residential">
                <v-list-item-title>Residential Inspections</v-list-item-title>
              </v-list-item>
              <v-list-item to="/about">
                <v-list-item-title>About Us</v-list-item-title>
              </v-list-item>
              <v-list-item to="/contact">
                <v-list-item-title>Contact</v-list-item-title>
              </v-list-item>
            </v-list>
      </v-menu>
      <img src="/Logo_Final_2021_Website.png">
      <v-btn class="hidden-md-and-down menu-btn" to="/" elevation="0">Home</v-btn>
      <v-btn class="hidden-md-and-down menu-btn" to="/construction" elevation="0">Construction Inspections</v-btn>
      <v-btn class="hidden-md-and-down menu-btn" to="/residential" elevation="0">Residential Inspections</v-btn>
      <v-btn class="hidden-md-and-down menu-btn" to="/about" elevation="0">About Us</v-btn>
      <v-btn class="hidden-md-and-down menu-btn" to="/contact" elevation="0">Contact</v-btn>
      <v-spacer></v-spacer>
      <v-btn dark color="#0d3874" to="request" elevation="0">Request Inspection</v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
    <v-footer>
      <v-col cols="12" md="4">
        <v-row>
          <v-spacer></v-spacer>
          <v-col style="color: #0d3874; font-size: 24px; font-weight: 400;">Our Clients</v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-col><img src="/First-United.png" height="64"></v-col>
          <v-col><img src="/INDEPENDENT.png" height="64"></v-col>
        </v-row>
      </v-col>
      <v-col cols="12"  md="4" class="align-self-baseline">
        Our business is veteran and family owned, headquartered in Boerne, TX. We take great pride in serving businesses
        across the state of Texas and aim to build a company legacy that can be passed down through generations to come.<br>
        <br>
        Allgood Inspections of San Antonio: License # 25051 | <a href="/trec.pdf" target="_blank">Texas Real Estate Commission Consumer Protection Notice</a>
      </v-col>
      <v-col cols="12"  md="4" class="align-self-baseline">
        CONTACT US<br>
        <v-icon>mdi-phone</v-icon><a href="tel:3017691181">301.769.1181 (Justin)</a><br>
        <v-icon>mdi-phone</v-icon><a href="tel:3017691181">817.999.3274 (Ross)</a><br>
        <v-icon>mdi-email</v-icon><a href="mailto:justin.shaw@allgoodinspections.com">justin.shaw@allgoodinspections.com (Justin)</a><br>
        <v-icon>mdi-email</v-icon><a href="ross.penney@allgoodinspections.com">ross.penney@allgoodinspections.com (Ross)</a><br>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
.v-application{
  font-family: 'Open Sans', sans-serif !important;
}

a{
  text-decoration: none;
}

.menu-btn{
  color: #0d3874 !important;
  font-size: 18px !important;
  font-weight: 400
}
</style>
