<template>
  <div>
    <House>
      Please send request to info@allgoodinspections.com for construction or residential inspection request. <br>
      <span class="hidden-sm-and-down">For licensed inspections, be sure to include size, location, and approximate age of home.</span>
    </House>
    <v-container fluid>
      <v-row>
        <v-col class="hidden-sm-and-down"></v-col>
        <v-col>
          <p class="text-center"><img src="/logo.jpg"></p>
          <p class="text-center">"As the proud owners of Allgood Inspections, we would love to share what we do and how we can earn an opportunity to work with you or your business."</p>
          <h2>CONTACT US</h2>
          <v-icon>mdi-phone</v-icon><a href="tel:3017691181">301.769.1181 (Justin)</a><br>
          <v-icon>mdi-phone</v-icon><a href="tel:3017691181">817.999.3274 (Ross)</a><br>
          <v-icon>mdi-email</v-icon><a href="mailto:justin.shaw@allgoodinspections.com">justin.shaw@allgoodinspections.com (Justin)</a><br>
          <v-icon>mdi-email</v-icon><a href="ross.penney@allgoodinspections.com">ross.penney@allgoodinspections.com (Ross)</a><br>
          <br>
          <h2>SEND US AN INQUIRY</h2>
          <v-icon>mdi-email</v-icon><a href="mailto:info@allgoodinspections.com">info@allgoodinspections.com</a><br>

        </v-col>
        <v-col class="hidden-sm-and-down"></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import House from "../components/House";
export default {
  name: "Contact",
  components: {House}
}
</script>

<style scoped>

</style>
