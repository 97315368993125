<template>
  <div>
    <House>REQUEST AN INSPECTION</House>
    <v-container fluid>
      <h1 class="text-center">Thank you for trusting Allgood Inspections as your provider!  Call 817.999.3274 to schedule or book with us NOW!</h1>
      <iframe src='https://www.spectora.com/home-inspectors/my-inspection-company-c756866cf0/schedule?iframe=1' style='border:0;height:1100px;width:100%;'></iframe>
    </v-container>
  </div>
</template>

<script>
import House from "../components/House";

export default {
  name: "Request",
  components: {House}

}
</script>

<style scoped>

</style>
