import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Construction from "../views/Construction";
import Contact from "../views/Contact";
import Request from "../views/Request";
import Residential from "../views/Residential";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/construction',
    name: 'Construction',
    component: Construction
  },
  {
    path: '/residential',
    name: 'Residential',
    component: Residential
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/request',
    name: 'Request',
    component: Request
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
